import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";

const Data = [
    {
        countNum: 199,
        countTitle: 'Happy Clients.',
    },
    {
        countNum: 575,
        countTitle: 'Employees',
    },
    {
        countNum: 69,
        countTitle: 'Useful Programs',
    },
    {
        countNum: 500,
        countTitle: 'Useful Programs',
    },
];
const SlipFour = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <h4 className="title">Nossos <span className="theme-gradient" >Diferenciais</span></h4>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <p className="description">Nossa prioridade é a qualidade, segurança e proximidade com nossos clientes, com suporte ágil e direto com o profissional, via telefone, e-mail ou whatsapp, sem burocracias ou bate-papos com robôs.</p>

                                <Link className="btn-default btn-border" to={'/contato#formulario'}>Entrar em contato</Link>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail" >
                            <img src="./images/bg/diferenciais.jpg" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipFour;