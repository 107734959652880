import { useEffect, useState } from 'react'
import Message from './Message'
import Send from './Send'

const Chat = ({ open }) => {
    //? Local storage
    const chatUser = localStorage.getItem('chatUser')
    const { id, name } = JSON.parse(chatUser)

    const firstMessage = [
        {
            id: '0',
            message: `Olá ${name}, como posso te ajudar?`,
            date: '10:00',
            user: {
                type: 'support',
                name: 'Precisa'
            }
        }
    ]

    const [messages, setMessages] = useState(firstMessage)

    const getAllMessages = () => {
        //? Get All messages from API
        fetch(`${process.env.REACT_APP_CHAT_API}?type=getAllMessages&id=${id}`)
            .then(res => res.json())
            .then(data => {
                const newMessages = [...firstMessage]
                if (data && data.length > 0) newMessages.push(...data)
                setMessages(newMessages)
                scroll()
            })
            .catch(err => console.log(err))
    }

    const sendMessage = e => {
        e.preventDefault()
        const message = e.target[0].value
        if (message.length > 0) {
            //? Send message to API
            fetch(`${process.env.REACT_APP_CHAT_API}?type=sendMessage&id=${id}&message=${message}`)
                .then(res => res.json())
                .then(data => {
                    getNewMessages()
                })
                .catch(err => console.log(err))
        }
    }

    //? Scroll to bottom
    const scroll = () => {
        setTimeout(() => {
            const chat = document.getElementById('chat')
            chat.scrollTop = chat.scrollHeight
        }, 200)
    }

    //? Obtém novas mensagens (id > lastId)
    const getNewMessages = () => {
        const lastId = messages[messages.length - 1].id ?? '0'

        fetch(`${process.env.REACT_APP_CHAT_API}?type=getNewMessages&id=${id}&lastId=${lastId}`)
            .then(res => res.json())
            .then(data => {
                if (data && data.length > 0) {
                    const newMessage = [...messages]
                    data && data.map(item => newMessage.push(item))
                    setMessages(newMessage)
                    scroll()
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (open) {
            //* Só busca mensagens se box estiver aberto
            const interval = setInterval(() => {
                getNewMessages()
            }, 3000) // 3 segundos

            return () => {
                clearInterval(interval) // Limpa o intervalo ao desmontar o componente
            }
        }
    }, [])

    useEffect(() => {
        getAllMessages()
    }, [])

    return (
        messages && (
            <>
                <div
                    id='chat'
                    style={{
                        backgroundColor: '#f6f6f6',
                        paddingBottom: '20px',
                        height: '100%',
                        borderLeft: '1px solid #E0E0E0',
                        borderRight: '1px solid #E0E0E0',
                        overflowY: 'scroll'
                    }}
                >
                    <Message messages={messages} />
                </div>
                <Send sendMessage={sendMessage} />
            </>
        )
    )
}

export default Chat
