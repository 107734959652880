import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";
import '../../assets/css/index.css'

const TestimonialData = [
    {
        form: "Joaçaba - SC",
        description: '"Trabalho com sistemas diversos de controle, acompanhamento, manutenção, avaliação, etc. Conheço o PRECISA desde 2012 e posso afirmar que de vários sistemas que ja usei é um dos mais fácil para aprendizado e manuseio. Por ser um sistema online com excelente navegabilidade nos dá a praticidade de poder usá-lo fora do ambiente de trabalho Completo, oferece recurso para todas as necessidades e demandas do setor E Não dá para deixar de comentar sobre o Suporte, que ao contrário da telefonia móvel, não te deixa esperando, o SAC é muito bem capacitado e profissional, tu acaba amigo do pessoal e se você  solicitar ajuda de algum funcionário que está de férias, eles nem ficarão bravos haha. Enfim, o PRECISA é um sistema excelente para arquivamento de dados, eliminação de arquivo físico e traz facilidade/praticidade para os operadores dele."',
        name: "Adriano Nora",
        subtitle: "Técnico Administrativo",
        image: "adriano"
    },
    {
        form: "Xanxerê - SC",
        description: "Sistema de Informação de qualidade é o primeiro passo para que as ações na Política de Assistência Social ganhem visibilidade, gerando indicadores necessários para a atuação das equipes de trabalho.  A informação registrada precisa ser compreendida como parte integrante do processo do planejamento, execução, avaliação e monitoramento da política no âmbito municipal. Contar com um sistema de informação adequado e que atenda as necessidades reais de usabilidade nos encontramos com a Empresa Precisa Tecnologia! Uma equipe atenciosa e comprometida com seus clientes e que busca constantemente se aprimorar para atender com agilidade as demandas que surgem diariamente na rotina de trabalho das equipes de Assistência Social.",
        name: "Aline",
        subtitle: "Assistente Social",
        image: "aline"
    },
    {
        form: "Abelardo luz - SC",
        description: '"Falar do sistema da precisa, o sistema de Gestão de Assitência Social é muito fácil, pois o mesmo possui várias ferramentas, sendo todas claras, concisas, consistentes, eficientes e atrativas, e com todas essas características, facilita e muito a interação do usuário com o sistema, vindo assim ajudar imensamente no cotidiano de quem precisa de seus dados para fazer um trabalho eficaz. Em relação a suporte e assistência técnica, esse é um ponto muito forte, pois sempre que solicitado, fomos prontamente atendidos, sendo via telefone, whatsapp, skype e também pessoalmente, com deslocamento de técnicos para melhor atendimento e esclarecimento de dúvidas. As alterações de sistemas, emissão de relatórios, consultas aos dados dos usuários também é outro ponto positivo. Dessa forma posso afirmar que o sistema de Gestão de Assitência Social com certeza é uma das melhores ferramentas de trabalho que tivemos até a hoje."',
        name: "Enir",
        subtitle: "Assistente Social",
        image: "enir"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-9 col-md-10 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description descriptionTestimonial">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-2 col-md-2">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















