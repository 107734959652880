import React from 'react'
import './card.css'
import { FiLink, FiRefreshCcw } from "react-icons/fi";
import { ImFilesEmpty } from "react-icons/im";
import { AiOutlineIdcard, AiOutlineBarChart, AiOutlineUser, } from "react-icons/ai";
import { FaUmbrella, FaSitemap, FaPenSquare, FaArrowsAltV, FaUserPlus, FaMoneyBillAlt, FaPaperPlane, FaHome, FaUsers, FaGamepad, FaPhoneAlt, FaCodeBranch, FaRegEnvelope, FaWindowRestore, FaShieldAlt, FaLock, FaRegChartBar, FaUserSecret, FaFileAlt, FaBan, FaRegHeart, FaThumbtack, FaHandsHelping, FaBullhorn, FaGavel, FaRegObjectUngroup, FaBook, } from "react-icons/fa";
import { VscFiles, } from "react-icons/vsc";
import { TbPhoneCall, } from "react-icons/tb";
import CalltoActionFive from '../calltoaction/CalltoActionFive';

const AssistenciaSocialContent = () => {
    return (
        <>
            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <AiOutlineIdcard
                        color="#ED3237"
                        size={40}
                    />
                    <h6>CADASTRO DE FAMILIAR E/OU CIDADÃO</h6>
                </div>
                <p>Chamamos de coração do Sistema, a maioria das ações partem deste módulo:</p>
                <ul>
                    <li>Cadastro completo de dados do Domicílio;</li>
                    <li>Cadastro completo de dados da Família;</li>
                    <li>Cadastro completo de dados do Cidadão;</li>
                    <li>Unidades referenciadas;</li>
                    <li>Imagens do Domicílio;</li>
                </ul>
            </div>
            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FiLink
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ANEXAR DOCUMENTOS</h6>
                </div>
                <p>Documentos Anexos e imagens dos cidadãos, possibilidade de anexar todos os documentos do cidadão:</p>
                <ul>
                    <li>CPF;</li>
                    <li>RG;</li>
                    <li>Certidão de Nascimento/Casamento/Óbito;</li>
                    <li>Título de Eleitor;</li>
                    <li>Comprovante de Endereço;</li>
                    <li>Comprovante de Renda;</li>
                    <li>Boletim de Ocorrência;</li>
                    <li>Carteira de Trabalho;</li>
                    <li>Ato Infracional;</li>
                    <li>Ofício;</li>
                    <li>Passaporte;</li>
                    <li>Comprovante de Escolaridade.</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaUmbrella
                        color="#ED3237"
                        size={40}
                    />
                    <h6>GESTÃO DE PROGRAMAS, SERVIÇOS E BENEFÍCIOS</h6>
                </div>
                <ul>
                    <li>Permite incluir e gerir todos os Programas/Serviços/Benefício das esferas Federal, Estadual e Municipal;</li>
                    <li>Inclusão de forma intuitiva;</li>
                    <li>Inclusão automática de famílias Auxilio brasil;</li>
                    <li>Fácil manutenção permitindo alterações e consultas;</li>
                    <li>Consultas e relatórios com dados atualizados;</li>
                    <li>Gráficos e quantitativos de acompanhados e demandas;</li>
                    <li>Alertar o profissioal caso o cidadão seja público alvo de um programa social;</li>
                    <li>PAIF;</li>
                    <li>PAEFI;</li>
                    <li>MSE-PSC;</li>
                    <li>MSE-LA;</li>
                    <li>Auxílio brasil;</li>
                    <li>BPC Idoso;</li>
                    <li>BPC Deficiênte;</li>
                    <li>PSB no Domicílio Idoso e/ou PCD;</li>
                    <li>SCFV.</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaSitemap
                        color="#ED3237"
                        size={40}
                    />
                    <h6>MOVIMENTAÇÃO DO CIDADÃO</h6>
                </div>
                <ul>
                    <li>Transferência de cidadãos entre famílias;</li>
                    <li>Transferência para outro município;</li>
                    <li>Histórico de transferência e movimentações;</li>
                    <li>Registro de óbito com dados o óbito;</li>
                    <li>Inativação do cidadão;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaPenSquare
                        color="#ED3237"
                        size={40}
                    />
                    <h6>PIA E PAF</h6>
                </div>
                <p>Crie Plano Individual de Atendimento e Plano de Acompanhamento Familiar facilmente através da ferramenta de Questionário, com possibilidade de criar perguntas e escolher as respostar em diversos formatos, data, numero, texto, alterenativa único e multiplas alternativas;</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaArrowsAltV
                        color="#ED3237"
                        size={40}
                    />
                    <h6>VULNERABILIDADES E POTENCIALIDADES</h6>
                </div>
                <ul>
                    <li>Incluir e fazer a manutenção das Vulnerabilidades e Potencialidades;</li>
                    <li>Gerenciar e extrair dados de Vulnerabilidades;</li>
                    <li>Elencar as potencialidades dos cidadão e famílias;</li>
                    <li>Violências e/ou Violações;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <VscFiles
                        color="#ED3237"
                        size={40}
                    />
                    <h6>UNIFICAÇÕES</h6>
                </div>
                <ul>
                    <li>Rotina de unificação inteligente que identifica bairros e localidades, principalmente importadas do CadUnico, que são as mesmas mas com grafia diferente;</li>
                    <li>Possibilidade de unificação de Cidadãos totalmente segura mantendo todos os registros e histórico inalteráveis;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaUserPlus
                        color="#ED3237"
                        size={40}
                    />
                    <h6>RECEPÇÃO DO EQUIPAMENTOS</h6>
                </div>
                <p>Módulo recepção para profissional de nível médio consultar dados da família, atualizar dados e auxiliar na triagem para atendimento dos profissionais técnicos;</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <AiOutlineBarChart
                        color="#ED3237"
                        size={40}
                    />
                    <h6>REGISTRO MENSAL DE ATENDIMENTOS</h6>
                </div>
                <p>RMA conforme Resolução CIT nº4/2011 alterada pela Resolução CIT nº02/2017.</p>
                <ul>
                    <li>Geração automática de todos os campos do RMA nas unidades CRAS, CREAS e CENTRO POP;</li>
                    <li>Geração de relatório de conferência dos dados gerados no RMA;</li>
                    <li>Exportação do RMA em XML;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaMoneyBillAlt
                        color="#ED3237"
                        size={40}
                    />
                    <h6>GESTÃO DE BENEFÍCIOS</h6>
                </div>
                <ul>
                    <li>Módulo que permite gerir os todos os benefícios fornecidos pela Assistência Social:</li>
                    <ul className='liInternos'>
                        <li>Benefício Eventual;</li>
                        <li>Benefício Emergencial;</li>
                        <li>Doações;</li>
                    </ul>
                    <li>Controle de Cotas mensal em quantidade ou valor, por benefício por unidade;</li>
                    <li>Demanda reprimida de benefícios;</li>
                    <li>Gerenciamento e Controle de entrega de benefícios;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaPaperPlane
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ENCAMINHAMENTOS</h6>
                </div>
                <ul>
                    <li>Encaminhamento e Recebimentos automáticos dentro da rede de Assistência Social;</li>
                    <li>Geração de formulário de encaminhamento automático para encanhamentos para fora da Rede de Assistência Social;</li>
                    <li>Gerenciamento de recebimento de encaminhamento;</li>
                    <li>Alerta de Encaminhamentos aguardando Recebimento;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <AiOutlineUser
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ATENDIMENTO E ACOMPANHAMENTO AO CIDADÃO</h6>
                </div>
                <p>Módulo que permite redigitar todo o atendimento e acompanhamento realizado ao ciadão/família:</p>
                <ul>
                    <li>Atendimento familiar ou individual;</li>
                    <li>Atendimento por equipe de referencia, podendo citar profissionais que estiveram presentes no atendimento;</li>
                    <li>Citar cidadão que estiveram presentes no atendimento;</li>
                    <li>Espaço para relatos confidenciais, permitindo o bloqueio por unidade, grupo de profissionais e individual</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaHome
                        color="#ED3237"
                        size={40}
                    />
                    <h6>VISITA</h6>
                </div>
                <p>Módulo que permite registrar todo trabalho realizado pela equipe fora do equipamento:</p>
                <ul>
                    <li>Visita Acompanhamento Familiar;</li>
                    <li>Visita Acompanhamento Individual;</li>
                    <li>Visita a Pedido de uma unidade, profissional ou anonimo;;</li>
                    <li>Abordagem Social;</li>
                    <li>Busca Ativa;</li>
                    <li>Monitorada;</li>
                    <li>Visita do tipo Atendimento;</li>
                    <li>Estas podem ser no domicílio da família, em uma instituição ou a um local;</li>
                    <li>Opção para laçar quando não houve a visita e o motivo;</li>
                    <li>Cidadão citados ou participantes na visita;</li>
                    <li>Fotos da visita;</li>

                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <TbPhoneCall
                        color="#ED3237"
                        size={40}
                    />
                    <h6>DENÚNCIA</h6>
                </div>
                <p>Módulo especifico para registro de denuncias recebidas:</p>
                <ul>
                    <li>Registro de denuncia pode ser anonima ou identificada;</li>
                    <li>Motivo da denuncia para levantamento de indicadores;</li>
                    <li>Encaminhamento de denuncia;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaUsers
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ATIVIDADES EM GRUPO</h6>
                </div>
                <ul>
                    <li>Monte grupos de PAIF, PAEFI, mulheres, idosos, jovens;</li>
                    <li>Registre as atividades com fotos e selecione presenças e faltas dos cidadãos;</li>
                    <li>Crie um Mutirão e registre atividades lançando a quantidade de cidadãos que participaram;</li>
                    <li>Gere lista de presença para assinatura;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaGamepad
                        color="#ED3237"
                        size={40}
                    />
                    <h6>GERENCIAMENTO DO SCFV</h6>
                </div>
                <ul>
                    <li>Gerencie turma, cidadãos, profissionais de referência, horários, atividades e presença;</li>
                    <li>Adicione fotos das atividades;</li>
                    <li>Imprima listas de presença e relatórios de atividade;</li>
                    <li>Alimentação automática no RMA do CRAS;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaBook
                        color="#ED3237"
                        size={40}
                    />
                    <h6>AGENDA</h6>
                </div>
                <ul>
                    <li>Crie agenda para profissional ou grupo de profissionais;</li>
                    <li>Compartilhe a agenda para outras unidades;</li>
                    <li>Gere comprovante de agendamento;</li>
                    <li>Receba lembrete de agendamentos para o dia;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaRegObjectUngroup
                        color="#ED3237"
                        size={40}
                    />
                    <h6>INTEGRAÇÕES - EXPORTAÇÕES</h6>
                </div>
                <ul>
                    <li>Importe todos os dados do CADÚNICO. Importação inteligente, alterando apenas os dados que foram atualizados no Cadastro Único;</li>
                    <li>Importe e compartilhe na rede os dados do SIBEC (Sistema de Benefícios ao Cidadão é uma ferramenta que permite a administração dos benefícios financeiros do Programa Bolsa Família - PBF);</li>
                    <li>Importe dados do SICOM (Sistema de Condicionalidades do Programa Auxílio Brasil);</li>
                    <li>Importe dados de outros programas estaduais como Viva Leite, Ação Jovem e Renda Cidadã;</li>
                    <li>Exporte todas consultas em formato XLS (Arquivo do Microsoft Excel);</li>
                    <li>Exporte Registro Mensal de Atendimento (RMA) em formato XML e importe diretamente na página SAGI;</li>
                    <li>Exporte dados para geração de Cartão Social, envie dados completo para criação da carteira do inclusive com foto;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaGavel
                        color="#ED3237"
                        size={40}
                    />
                    <h6>CASAMENTO COMUNITÁRIO</h6>
                </div>
                <p>Registre e gerencie casamento comunitário;</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaBullhorn
                        color="#ED3237"
                        size={40}
                    />
                    <h6>CONSELHO MUNICIPAL</h6>
                </div>
                <ul>
                    <li>Gerencie os conselhos municipais de Assistência Social, Direitos da Criança e Adolescente, Direitos da Mulher, Conselho do Idoso, Pessoa com Deficiência e Segurança Alimentar e Nutricional;</li>
                    <li>Lance os conselheiros;</li>
                    <li>Registre as reuniões com as pautas, as presenças e as deliberações;</li>
                    <li>Importe dados de outros programas estaduais como Viva Leite, Ação Jovem e Renda Cidadã;</li>
                    <li>Armazene documentos da reunião como lista de presença, documentos e fotos;</li>
                    <li>Armazene e imprima a ata da reunião;</li>
                </ul>
            </div>


            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaHandsHelping
                        color="#ED3237"
                        size={40}
                    />
                    <h6>REUNIÃO E ARTICULAÇÃO</h6>
                </div>
                <ul>
                    <li>Registre mediação familiar, inclua unidades participantes, profissionais participantes, adicione imagens e documentos;</li>
                    <li>Registre treinamentos internos com profissionais presentes;</li>
                    <li>Registre participação em palestras e treinamentos;</li>
                    <li>Registre capacitações dos profissionais;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaGavel
                        color="#ED3237"
                        size={40}
                    />
                    <h6>SERVIÇO COMUNITÁRIO</h6>
                </div>
                <p>Gerencie e monitore os Prestadores de Serviços Comunitário (PSC)</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaThumbtack
                        color="#ED3237"
                        size={40}
                    />
                    <h6>GEORREFERENCIAMENTO</h6>
                    
                </div>
                <p>Explore o mapa do município com diversos marcadores facilitando identificar bairros, ruas e localidades que tenham:</p>
                <ul>
                    <li>Vulnerabilidades;</li>
                    <li>Abordagem Social;</li>
                    <li>Família em situação de Extrema Pobreza;</li>
                    <li>PAIF;</li>
                    <li>PAEFI;</li>
                    <li>BPC;</li>
                    <li>Bolsa Família;</li>
                    <li>PSC;</li>
                    <li>LA;</li>
                    <li>Renda familiar</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaRegHeart
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ACOLHIMENTO</h6>
                    
                </div>
                <ul>
                    <li>Monitore e Gerencie os cidadãos em Acolhimento Institucional;</li>
                    <li>Cadastre famílias acolhedoras;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaBan
                        color="#ED3237"
                        size={40}
                    />
                    <h6>REGIME PRISIONAL</h6>
                    
                </div>
                <p>Gerencie cidadãos apenados lançando Motivos do Cárcere, Tipos de Pena, data de entrada e previsão de saída;</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaFileAlt
                        color="#ED3237"
                        size={40}
                    />
                    <h6>RELATÓRIOS</h6>
                    
                </div>
                <ul>
                    <li>Os relatórios permitem que você possa ter uma visão mais ampla sobre a situação da Secretaria de Assistência Social e possa planejar-se de forma a evitar dívidas, otimizar suas atividades operacionais e assim aumentar a qualidade;</li>
                    <li>Hoje o sistema conta com mais de 40 relatórios fixos;</li>
                </ul>
            </div>
            

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <ImFilesEmpty
                        color="#ED3237"
                        size={40}
                    />
                    <h6>RELATÓRIOS PERSONALIZADOS</h6>
                    
                </div>
                <ul>
                    <li>O grande diferencial dos sistemas da PRECISA está na elaboração e disponibilidade de criação de qualquer relatório personalizado. Além dos relatórios já prontos no sistema, cada cliente, solicita o seu relatório conforme a sua demanda;</li>
                    <li>Formulários personalizados. Todo e qualquer formulário que hoje é gerado pelo Word ou até mesmo manual será implementado, possibilitando a impressão no sistema, conforme modelo do Município:</li>
                        <ul className='liInternos'>
                            <li>Declaração de Comparecimento;</li>
                            <li>Autorização de Fornecimento de Benefício Eventual;</li>
                            <li>Formulário de Inscrição no SCFV;</li>
                            <li>Solicitação de Segunda Via de Certidão (nascimento, casamento e óbito);</li>
                            <li>Parecer Social;</li>
                            <li>Estudo Social</li>
                            <li>Listas de Presença para SCFV e Grupos;</li>
                            <li>Autorização para Retirada de Benefício Eventual em outra Unidade ou Empresa;</li>
                            <li>Emissão de Carteira do Idoso e/ou Deficiente;</li>
                            <li>Declaração de Hipossuficiência;</li>
                            <li>Emissão de Vale Transporte para participantes dos Grupos e SCFV;</li>
                            <li>Recibo de Benefício;</li>
                            <li>Comprovante de Agendamento;</li>
                            <li>Autorização de Passagem;</li>
                            <li>Encaminhamento para Funerária para Auxílio Funeral;</li>
                            <li>Ata de Reunião e Articulação;</li>
                            <li>Ata da Reuniões dos Conselhos;</li>
                            <li>PIA / PAF;</li>
                        </ul>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaFileAlt
                        color="#ED3237"
                        size={40}
                    />
                    <h6>FORMULÁRIOS PERSONALIZADOS</h6>
                    
                </div>
                <p>Todo e qualquer formulário que hoje é gerado pelo Word ou até mesmo manual será implementado, possibilitando a impressão no sistema, conforme modelo do Município:</p>
                <ul>
                    <li>Declaração de Comparecimento;</li>
                    <li>Autorização de Fornecimento de Benefício Eventual;</li>
                    <li>Formulário de Inscrição no SCFV;</li>
                    <li>Solicitação de Segunda Via de Certidão (nascimento, casamento e óbito);</li>
                    <li>Formulário de Encaminhamento</li>
                    <li>Parecer Social;</li>
                    <li>Bolsa Família;</li>
                    <li>Estudo Social;</li>
                    <li>Listas de Presença para SCFV e Grupos;;</li>
                    <li>Autorização para Retirada de Benefício Eventual em outra Unidade ou Empresa;</li>
                    <li>Emissão de Carteira do Idoso e/ou Deficiente;</li>
                    <li>Declaração de Hipossuficiência;</li>
                    <li>Emissão de Vale Transporte para participantes dos Grupos e SCFV;</li>
                    <li>Recibo de Benefício;</li>
                    <li>Comprovante de Agendamento;</li>
                    <li>Autorização de Passagem;</li>
                    <li>Encaminhamento para Funerária para Auxílio Funeral;</li>
                    <li>Ata de Reunião e Articulação;</li>
                    <li>Ata da Reuniões dos Conselhos</li>
                    <li>PIA / PAF;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaUserSecret
                        color="#ED3237"
                        size={40}
                    />
                    <h6>VIGILÂNCIA SOCIOASSISTENCIAL</h6>
                    
                </div>
                <ul>
                    <li>Tenha em mãos todos os dados quantitativos e qualitativos para auxiliar nas tomadas de decisões;</li>
                    <li>Monitore e avalie os acompanhamentos das famílias, principalmente PAIF e PAEFI;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaRegChartBar
                        color="#ED3237"
                        size={40}
                    />
                    <h6>GRÁFICOS GERENCIAIS</h6>
                    
                </div>
                <p>Extraia dados quantitativos através de diversos gráficos;</p>
            </div>


            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaLock
                        color="#ED3237"
                        size={40}
                    />
                    <h6>SEGURANÇA DO SERVIDOR</h6>
                </div>
                <ul>
                    <li>Sistema alocado em servidores a AWS (O Amazon Elastic Compute Cloud (Amazon EC2) é um web service que disponibiliza capacidade computacional segura e redimensionável na nuvem. Ele foi projetado para facilitar a computação em nuvem na escala da web para os desenvolvedores. A interface de web service simples do Amazon EC2 permite que você obtenha e configure a capacidade com o mínimo de esforço. Ele oferece controle total de seus recursos computacionais e lhe permite trabalhar no ambiente computacional comprovado da Amazon.) https://images.app.goo.gl/c2hfh6vgRnu2CuPh6;</li>
                    <li>Sistema disponível 24 horas, 7 dias por semana;</li>
                    <li>Backups diários, armazenados por tempo indeterminado;</li>
                </ul>
            </div>


            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaShieldAlt
                        color="#ED3237"
                        size={40}
                    />
                    <h6>SEGURANÇA DO SISTEMA</h6>
                </div>
                <ul>
                    <li>Acesso ao sistema com total segurança através de senha criptografada e com telas e módulos confidenciais a profissionais Técnicos de Proteção Social Básica e Proteção Social Especial. Mantendo assim o sigilo de todas as informações;</li>
                    <li>Sistema com LOG de utilização, todas de Inclusão, Alteração e Exclusão ficam registrados;</li>
                    <li>Integridade referencial de dados, não permitindo exclusão de dados que tenham referência com outras tabelas;</li>
                    <li>Validação de dados de entrada, não permitindo inclusão de texto em campos numéricos. Validação de data, CPF e CNPJ;</li>
                    <li>Utilização de língua portuguesa em todos os módulos do sistema;</li>
                </ul>
            </div>


            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaWindowRestore
                        color="#ED3237"
                        size={40}
                    />
                    <h6>TECNOLOGIA</h6>
                </div>
                <ul>
                    <li>Sistema WEB disponível com acesso através dos principais navegadores do mercado;</li>
                    <li>Campos de autopreenchimento;</li>
                    <li>Navegação através de janelas modais;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaRegEnvelope
                        color="#ED3237"
                        size={40}
                    />
                    <h6>MENSAGEM INTERNA</h6>
                </div>
                <ul>
                    <li>Ferramenta que permite envio de mensagem por dentro do sistema;</li>
                    <li>Agendamento de envio de mensagem;</li>
                    <li>Envio para um ou grupo de profissionais;</li>
                    <li>Anexo na mensagem;</li>
                    <li>Monitorar o recebimento e leitura do destinatário;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FiRefreshCcw
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ATUALIZAÇÃO CONSTANTE</h6>
                </div>
                <ul>
                    <li>Sempre acompanhando a tecnologia e as sugestões dos clientes, a PRECISA libera regularmente novas versões do sistema;</li>
                    <li>Todas as implementações estão disponíveis para todos os profissionais em um documento de Liberação de Versão;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaCodeBranch
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ACESSO PARA TODA REDE DE ASSISTÊNCIA SOCIAL</h6>
                </div>
                <p>Além de atender 100% da Secretaria Municipal de Assistência Social, o sistema pode ser ampliado para toda a rede de proteção:</p>
                <ul>
                    <li>Conselho Tutelar;</li>
                    <li>Casa de Apoio;</li>
                    <li>APAE;</li>
                    <li>Instituições;</li>
                    <li>Saúde;</li>
                    <li>Educação;</li>
                    <li>Habitação;</li>
                </ul>
            </div>

            <div style={{marginBottom: '40px'}} className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaPhoneAlt
                        color="#ED3237"
                        size={40}
                    />
                    <h6>SUPORTE TÉCNICO COM RETORNO IMEDIATO</h6>
                </div>
                <ul>
                    <li>Tempo de resposta em menos de 5 minutos;</li>
                    <li>Telefone (49) 3322-8044 - ligue agora e teste;</li>
                    <li>Chat: precisatenologia.com.br</li>
                    <li>WhatsApp: (49) 988071003</li>
                    <li>E-mail: suporte@precisatecnologia.com.br</li>
                    <li>Controle de chamados permitindo o cliente acessar os detalhes da tarefa</li>
                </ul>
            </div>
            
            <CalltoActionFive/>

        </>
    )
}

export default AssistenciaSocialContent