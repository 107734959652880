import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FaWhatsapp } from "react-icons/fa";

const callToActionData = {
    title: "Ready to start creating a standard website?",
    subtitle: "Finest choice for your home & office",
    btnText: "Vamos conversar?",
    link: "https://wa.me/5549988159192"
}

const CalltoActionFive = () => {
    return (
        <div style={{ marginBottom: '40px' }} className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-6">
                        <h2>Entre em contato também pelo WhatsApp e tire todas as suas dúvidas</h2>
                    </div>
                    <div className="col-lg-6">
                        <div className="call-to-btn text-center text-lg-right">
                            <div className="button-group mt--30">
                                <a className="btn-default btn-large round" href={callToActionData.link} target="_blank" >
                                    <FaWhatsapp size={30} /> {callToActionData.btnText}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFive;