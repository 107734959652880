import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
// import BrandAssistenciaSocial from '../elements/brand/BrandAssistenciaSocial';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import TeamOne from "../elements/team/TeamOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import Copyright from '../common/footer/Copyright';
import SlipFour from "../elements/split/SlipFour";

import { useRef } from 'react';
import HeaderTopBar from '../common/header/HeaderTopBar';

const QuemSomos = () => {

    // Go to the specific div element
    const refHistoria = useRef(null);
    const refEquipe = useRef(null);
    const scrollToElement = () => {
        const url = window.location.href
        const partsUrl = url.split('#')
        if (partsUrl[1]) { // #algumacoisa
            switch (partsUrl[1]) {
                case 'historia':
                    refHistoria.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'equipe':
                    refEquipe.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
            }
        }
    }
    setTimeout(scrollToElement, 1000)

    // Calcula a idade
    const idadePrecisa = Math.floor(Math.ceil(Math.abs(new Date('2009-07-13').getTime() - new Date().getTime()) / (1000 * 3600 * 24)) / 365.25);

    return (
        <>
            <SEO title="Quem Somos - Precisa Tecnologia" />

            <main className="page-wrapper">
                <HeaderTopBar />

                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Image Header  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/quem-somos.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Nossa Empresa</span></h3>
                                    </div>
                                    <h1 className="title display-one">Quem somos<br />e o que <span className="theme-gradient" >fazemos</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Texto */}
                <div ref={refHistoria} className="service-area rn-section-gapTop mb--20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    <h3 className="title">Conheça um pouco<br />da nossa <span className="theme-gradient" >história</span>.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="mb--10">
                                    Somos uma empresa que surgiu em 13/07/2009, mesmo ano da tipificação socio assistencial, criada por Rodrigo Piovesan e Paulo André Testa. Rodrigo trabalha com tecnologia desde os seus 18 anos de idade, formado em sistemas de informação e Paulo trabalhou na área pública desde *, formado em gestão pública.
                                </p>
                                <p className="mb--10">
                                    O nome PRECISA surgiu através da ideia de representar PRECISÃO das informações. O símbolo é inspirado em um alvo, que busca a exatidão das informações e solulções corretas nas tomadas de decisões, representado por um dardo acertando o ponto de maior valor no alvo, de forma limpa, acertiva e forte.
                                </p>
                                <p className="mb--10">
                                    A ideia inicial da PRECISA era vender softwares destinados a area publica, porem encontraram a necessidade na área da assistencia social, desde então o foco foi voltado para esta área, fazendo com que o software de assistencia social evoluisse e entregasse um sistema de qualidade para os municipios, prestando suporte de uma forma diferente, imediata e direta, com contato direto com os técnicos do suporte, esclarecendo as dúvidas o mais rapido possivel.
                                </p>
                                <p className="mb--10">
                                    Hoje, com {idadePrecisa} anos e mais de 50 clientes atuamos nos 3 estados do sul do Brasil.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Números  */}
                <div className="container mb--100">
                    <CounterUpTwo column="col-lg-6 col-md-6 col-sm-6 col-12" counterStyle="counter-style-2" textALign="text-center" />
                </div>

                {/* Tela dividida com texto e galeria de imagens */}
                <SlipFour />

                <Separator />

                {/* Equipe */}
                <div ref={refEquipe} id='equipe' className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Nossos Especialistas"
                                    title="Conheça Nossa Equipe"
                                    description=""
                                />
                            </div>
                        </div>
                        <TeamOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />
                    </div>
                </div>

                <Separator />

                {/* Clientes  */}
                {/* <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Estão Conosco"
                                    title = "Alguns de Nossos Clientes"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandAssistenciaSocial brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </main>

            <FooterTwo />
            <Copyright />
        </>
    )
}

export default QuemSomos;