// import React from 'react'

import { useState } from 'react'
import IconChat from './Icons/IconChat'
import IconClose from './Icons/IconClose'
import Box from './Box'

const CallToAction = () => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    padding: '15px',
                    right: '20px',
                    bottom: '20px',
                    backgroundColor: '#FFF',
                    border: '1px solid #E0E0E0',
                    cursor: 'pointer',
                    zIndex: '1000'
                }}
                onClick={() => setOpen(!open)}
            >
                {open ? (
                    <IconClose style={{ fontSize: '50px', color: '#717171' }} />
                ) : (
                    <IconChat style={{ fontSize: '50px', color: '#0077BD' }} />
                )}
            </div>

            {/* Box do chat */}
            {open && <Box open={open} />}
        </>
    )
}

export default CallToAction
