import IconCheck from './Icons/IconCheck'

const Message = ({ messages }) => {
    return (
        <>
            {messages &&
                messages.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            justifyContent: item.user.type === 'client' ? 'flex-end' : 'flex-start',
                            position: 'relative'
                        }}
                    >
                        {/* Avatar Precisa */}
                        {item.user.type === 'support' && (
                            <img
                                src='https://github.com/PRECISACHAPECO.png'
                                alt='Avatar'
                                style={{ width: '30px', height: '30px', borderRadius: '50%', margin: '15px 0 0 10px' }}
                            />
                        )}

                        {/* Mensagem */}
                        <div
                            style={{
                                backgroundColor: item.user.type === 'client' ? '#0077BD' : '#FFF',
                                color: item.user.type === 'client' ? '#FFF' : '#000',
                                width: '70%',
                                border: '1px solid #E0E0E0',
                                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                                margin: '15px 5px',
                                padding: '10px',
                                fontSize: '14px',
                                borderRadius: '6px'
                            }}
                        >
                            {item.message}
                        </div>

                        {/* Informação do horário */}
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '-5px',
                                color: '#A0A0A0',
                                fontSize: '12px',
                                margin: item.user.type === 'client' ? '0 10px 0 0' : '0 0 0 45px'
                            }}
                        >
                            {item.user.type == 'support' && (
                                <span style={{ marginRight: '5px' }}>{item.user.name}</span>
                            )}
                            <span style={{ marginRight: '5px' }}>{item.date}</span>
                            {item.user.type == 'client' && <IconCheck />}
                        </div>
                    </div>
                ))}
        </>
    )
}

export default Message
