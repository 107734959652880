//* Icons
import { useState } from 'react'
import IconSend from './Icons/IconSend'

const Send = ({ sendMessage }) => {
    const [message, setMessage] = useState('')

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
                height: '60px',
                padding: '15px',
                backgroundColor: '#FFF',
                border: '1px solid #E0E0E0',
                borderRadius: '0 0 16px 16px'
            }}
        >
            <div style={{ width: '100%', height: '100%' }}>
                <form
                    onSubmit={e => {
                        setMessage('')
                        sendMessage(e)
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <input
                        type='text'
                        placeholder='Digite sua mensagem'
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            height: '100%'
                        }}
                    />

                    <div>
                        <button
                            type='submit'
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <IconSend style={{ fontSize: '25px', color: '#0077BD', cursor: 'pointer' }} />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Send
