import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Sua mensagem foi enviada com sucesso. Responderemos no e-mail o mais breve possível :)</p>
    )
}

function ContactForm({ props, formStyle }) {
    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE,
                process.env.REACT_APP_EMAILJS_TEMPLATE,
                e.target,
                process.env.REACT_APP_EMAILJS_USER
            )
            .then((result) => {
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 10000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Nome"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Endereço de email"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Número de telefone"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Assunto"
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Mensagem"
                    required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Enviar</button>
            </div>

            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
