import React from "react";
import SEO from "../common/SEO";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
import AssistenciaSocialContent from "../elements/solutionsContent/AssistenciaSocialContent";
import FooterTwo from '../common/footer/FooterTwo';

const AssistenciaSocial = () => {
    return (
        <>
            <SEO title="Assistência Social - Precisa Tecnologia" />
            <main className="page-wrapper">
                <HeaderTopBar />

                <div className="header-transparent-with-topbar">
                    <HeaderOne
                        btnStyle="btn-small btn-icon"
                        HeaderSTyle="header-not-transparent"
                    />
                </div>

                {/* Image Header  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/banner-assistencia-social.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Nossa Solução</span></h3>
                                    </div>
                                    <h1 className="title display-one">Assistência <span className="theme-gradient" >Social</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" style={{ marginTop: '6rem' }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Com o Sistema de Gestão da Assistência Social da PRECISA, todas as necessidades e acompanhamentos da área social municipal são administradas com qualidade e segurança de forma centralizada e compartilhada entre todos os equipamentos. Aprovado e indicado por mais de 40 clientes o sistema é totalmente adaptável às realidades do seu município, sem esquecer dos princípios da Tipificação dos Serviços Socioassistencial.</p>
                        </div>
                    </div>

                    <AssistenciaSocialContent />
                </div>

                {/*Rodapé*/}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    );
};

export default AssistenciaSocial;
