import React from 'react'
import './card.css'
import { FiLink, FiRefreshCcw } from "react-icons/fi";
import { ImFilesEmpty } from "react-icons/im";
import { AiOutlineIdcard, AiOutlineBarChart, AiOutlineUser, } from "react-icons/ai";
import { FaUmbrella, FaSitemap, FaPenSquare, FaArrowsAltV, FaUserPlus, FaMoneyBillAlt, FaPaperPlane, FaHome, FaUsers, FaGamepad, FaPhoneAlt, FaCodeBranch, FaRegEnvelope, FaWindowRestore, FaShieldAlt, FaLock, FaRegChartBar, FaUserSecret, FaFileAlt, FaBan, FaRegHeart, FaThumbtack, FaHandsHelping, FaBullhorn, FaGavel, FaRegObjectUngroup, FaBook, FaCubes, FaUpload,} from "react-icons/fa";
import { VscFiles, } from "react-icons/vsc";
import { TbPhoneCall, } from "react-icons/tb";
import CalltoActionFive from '../calltoaction/CalltoActionFive';



const HabitacaoContent = () => {
    return (
        <>
            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <AiOutlineIdcard
                        color="#ED3237"
                        size={40}
                    />
                    <h6>CADASTRO DE FAMILIAR E/OU CIDADÃO</h6>
                </div>
                <p>Cadastro completo e direto, prático e funcional. Em uma tela muito amigável em formato de rolagem (tudo em uma pagina) podemos ver dados do Domicílio, Integrantes da Família, Programas e Histórico de Atendimentos e Benefícios. Destaques neste módulo:</p>
                <ul>
                    <li>Endereço completo;</li>
                    <li>Situação Ambiental</li>
                        <ul className='liInternos'>
                            <li>Área de Risco;</li>
                            <li>Área Irregular;</li>
                            <li>Degradação Ambiental;</li>
                            <li>Desabrigado;</li>
                            <li>Desastre climático;</li>
                            <li>Insalubre;</li>
                            <li>Prioritária;</li>
                        </ul>
                    <li>Anexos da Família:</li>
                        <ul className='liInternos'>
                            <li>Adicione imagens, fotos, comprovantes de residência, declarações e documentos diversos em qualquer formato;</li>
                        </ul>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaUsers
                        color="#ED3237"
                        size={40}
                    />
                    <h6>CADASTRO DO CIDADÃO</h6>
                </div>
                <ul>
                    <li>Inclua novos cidadãos de forma rápida através da importação dos dados do Cadastro Único;</li>
                    <li>Cadastro completo e não obrigatório de vários campos essencial para análise e diagnóstico habitacional;</li>
                    <li>Anexe imagem, documentos, certidões e qualquer documento de forma fácil e direta;</li>
                    <li>Receba alerta de divergências de renda comparadas com as lançadas no Sistema de Gestão da Assistência Social (exclusivo para clientes que possuem sistema de gestão da Assistência Social);</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaRegChartBar
                        color="#ED3237"
                        size={40}
                    />
                    <h6>ESTATÍSTICAS</h6>
                </div>
                <p>Gerencie o cadastro e a qualidade em um painel de estatística</p>
            </div>
            
            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaCubes
                        color="#ED3237"
                        size={40}
                    />
                    <h6>INCLUA DADOS DO LOTE</h6>
                </div>
                <ul>
                    <li>Medidas do lote;</li>
                    <li>Logradouros Confrontantes;</li>
                    <li>Dados da compra e dados do vendedor;</li>
                </ul>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaFileAlt
                        color="#ED3237"
                        size={40}
                    />
                    <h6>RELATÓRIOS E FORMULÁRIOS</h6>
                </div>
                <p>Gere de forma rápida Protocolo de Inscrição Habitacional</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaUpload
                        color="#ED3237"
                        size={40}
                    />
                    <h6>Importações</h6>
                </div>
                <p>Realize a importação do Cadastro Único</p>
            </div>

            <div style={{marginBottom: '40px'}} className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaLock
                        color="#ED3237"
                        size={40}
                    />
                    <h6>SEGURANÇA DO SERVIDOR</h6>
                </div>
                <ul>
                    <li>Sistema disponível 24 horas, 7 dias por semana;</li>
                    <li>Backups diários, armazenados por tempo indeterminado;</li>
                    <li>Certificado SSL;</li>
                </ul>
            </div>
            
            <CalltoActionFive/>

        </>
    )
}

export default HabitacaoContent