const Header = ({ title }) => {
    return (
        <div
            style={{
                backgroundColor: '#0077BD',
                border: '1px solid #0077BD',
                width: '100%',
                height: '60px',
                padding: '15px',
                borderRadius: '16px 16px 0 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFF',
                fontWeight: 'bold'
            }}
        >
            {title}
        </div>
    )
}

export default Header
