import { useState } from 'react'

const Identifier = ({ login, setName, setEmail }) => {
    return (
        <form
            onSubmit={login}
            style={{
                backgroundColor: '#f6f6f6',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderLeft: '1px solid #E0E0E0',
                borderRight: '1px solid #E0E0E0',
                borderRadius: '0 0 16px 16px'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '80%',
                    gap: '10px'
                }}
            >
                {/* Titulo */}
                <div>
                    <h1
                        style={{
                            color: '#0077BD',
                            fontWeight: 'bold',
                            fontSize: '25px',
                            marginBottom: '10px'
                            // textAlign: 'center'
                        }}
                    >
                        Seja Bem-vindo(a) ✌️
                    </h1>
                    <p
                        style={{
                            color: '#818181',
                            fontSize: '14px',
                            lineHeight: '22px',
                            marginBottom: '10px'
                        }}
                    >
                        Insira seu nome e melhor e-mail, nossos especialistas responderão o mais rápido possível!
                    </p>
                </div>

                <input
                    type='text'
                    placeholder='Nome'
                    required
                    onChange={e => setName(e.target.value)}
                    style={{
                        border: '2px solid #818181',
                        width: '100%',
                        color: '#818181',
                        outline: 'none',
                        padding: '15px',
                        borderRadius: '10px'
                    }}
                />

                <input
                    type='email'
                    placeholder='E-mail'
                    required
                    onChange={e => setEmail(e.target.value)}
                    style={{
                        border: '2px solid #818181',
                        width: '100%',
                        color: '#818181',
                        outline: 'none',
                        padding: '15px',
                        borderRadius: '10px'
                    }}
                />

                <button
                    type='submit'
                    style={{
                        backgroundColor: '#0077BD',
                        border: 'none',
                        color: '#FFF',
                        fontWeight: 'bold',
                        padding: '15px',
                        borderRadius: '10px',
                        cursor: 'pointer'
                    }}
                >
                    Entrar
                </button>
            </div>
        </form>
    )
}

export default Identifier
