import React from 'react'
import './card.css'
import { FiLink, FiRefreshCcw } from "react-icons/fi";
import { FaRocket, FaMobileAlt, FaWhmcs, FaHeadset } from "react-icons/fa";
import { ImSvg, ImFilesEmpty } from "react-icons/im";
import { AiOutlineIdcard, AiOutlineBarChart, AiOutlineUser, } from "react-icons/ai";
import { FaUmbrella, FaSitemap, FaPenSquare, FaArrowsAltV, FaUserPlus, FaMoneyBillAlt, FaPaperPlane, FaHome, FaUsers, FaGamepad, FaPhoneAlt, FaCodeBranch, FaRegEnvelope, FaWindowRestore, FaShieldAlt, FaLock, FaRegChartBar, FaUserSecret, FaFileAlt, FaBan, FaRegHeart, FaThumbtack, FaHandsHelping, FaBullhorn, FaGavel, FaRegObjectUngroup, FaBook, FaCubes, FaUpload,} from "react-icons/fa";
import { VscFiles, } from "react-icons/vsc";
import { TbPhoneCall, } from "react-icons/tb";
import CalltoActionFive from '../calltoaction/CalltoActionFive';

const SoftwarePersonalizadoContent = () => {
    return (
        <>
            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaRocket color="#ED3237" size={40} />
                    <h6>SOFTWARE 100% WEB</h6>
                </div>
                <p>Nossos softwares são 100% web e rodam através de qualquer plataforma que tenha acesso à internet. Desta forma, não é necessário realizar a instalação no seu computador ou celular.</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaMobileAlt color="#ED3237" size={40} />
                    <h6>RESPONSIVO</h6>
                </div>
                <p>Softwares responsivos para adaptar o conteúdo para todos os tipos de tela. Com essa facilidade você não precisa se preocupar em acessar em somente um tipo de dispositivo.</p>
            </div>

            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaWhmcs color="#ED3237" size={40} />
                    <h6>CUSTOMIZADO PARA VOCÊ</h6>
                </div>
                <p>Software criado do zero <b>exclusivamente</b> para a sua ideia e estratégia de negócio, a fim de obter o melhor resultado para você, de forma eficaz e objetiva para o cenário da sua empresa.</p>
            </div>
            
            <div className='card bg-color-blackest cardManual'>
                <div className='titulo'>
                    <FaHeadset color="#ED3237" size={40} />
                    <h6>SUPORTE</h6>
                </div>
                <p>Nosso suporte é um diferencial da nossa empresa! Ao obter um software conosco, você terá uma equipe especializada no suporte pronta para esclarecer todas as suas dúvidas referente ao software.</p>
                <p>O suporte pode ser realizado por meio de telefone, e-mail ou WhatsApp.</p>
            </div>

            <div className='card bg-color-blackest cardManual' style={{marginBottom: '40px'}}>
                <div className='titulo'>
                    <FaFileAlt color="#ED3237" size={40} />
                    <h6>RELATÓRIOS</h6>
                </div>
                <p>Gere relatórios em formato PDF de forma prática e rápida para um maior controle do seu negócio.</p>
            </div>

            <CalltoActionFive/>
        </>
    )
}

export default SoftwarePersonalizadoContent