import React from "react";
import SEO from "../common/SEO";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
import HabitacaoContent from "../elements/solutionsContent/HabitacaoContent";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
import FooterTwo from '../common/footer/FooterTwo';

const Habitacao = () => {
    return (
        <>
            <SEO title="Habitação - Precisa Tecnologia" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <div className="header-transparent-with-topbar">
                    <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Image Header  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/banner-habitacao.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Nossa Solução</span></h3>
                                    </div>
                                    <h1 className="title display-one"><span className="theme-gradient" >Habitação</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" style={{ marginTop: '6rem' }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Sistema de Gestão para os programas habitacionais tem a finalidade de gerir os programas e suas especificidades, com cadastro das famílias, vinculação as famílias aos programas que elas se enquadram, programas de regularização fundiária, emissão de documentos pertinentes, emissão de relatórios estatísticos entre outros.</p>
                        </div>
                    </div>
                    <HabitacaoContent />
                </div>

                {/*Rodapé*/}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    );
};

export default Habitacao;
