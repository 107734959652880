import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import { useRef } from 'react';
import HeaderTopBar from '../../common/header/HeaderTopBar';
import HeaderOne from '../../common/header/HeaderOne';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';

const Contact = () => {

    // Go to the specific div element
    const refFormulario = useRef(null);
    const scrollToElement = () => {
        const url = window.location.href
        const partsUrl = url.split('#')
        if (partsUrl[1]) { // #algumacoisa
            switch (partsUrl[1]) {
                case 'formulario':
                    refFormulario.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
            }
        }
    }
    setTimeout(scrollToElement, 1000)

    return (
        <>
            <SEO title="Contato - Precisa Tecnologia" />

            <main className="page-wrapper">
                <HeaderTopBar />
                <div className="header-transparent-with-topbar">
                    <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Contato"
                                    title="Entre em contato"
                                    description="Escolha uma forma de contato conosco, responderemos você o mais breve possível."
                                />
                            </div>
                        </div>
                        <div ref={refFormulario}>
                            <ContactOne />
                        </div>
                    </div>
                </div>
                {/* End Contact Area  */}
            </main>

            {/*Rodapé*/}
            <FooterTwo />
            <Copyright />
        </>
    )
}
export default Contact;