import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefone de contato</h4>
                                        <p><a href="tel:+554933228044">(49) 3322 8044</a></p>
                                        <p><a href="https://api.whatsapp.com/send?phone=5549988071003&amp;text=Ol%C3%A1,%20vim%20pelo%20site,%20poderia%20me%20ajudar?" target="_blank">(49) 988071003</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Nosso endereço de e-mail</h4>
                                        <p><a href="mailto:suporte@precisatecnologia.com.br">suporte@precisatecnologia.com.br</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Nosso endereço</h4>
                                        <p>R. Minas Gerais, 533, Sala 206<br />Presidente Médici, Chapecó/SC</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20092.528783231515!2d-52.614155494963136!3d-27.096737686761134!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ad8e48b9a8d88b6!2sPRECISA%20Gest%C3%A3o%20em%20Tecnologia%20e%20Servi%C3%A7os%20Ltda!5e0!3m2!1spt-PT!2sbr!4v1666103157303!5m2!1spt-PT!2sbr"   width="100%" height="550px"  allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    )
}
export default ContactOne;