import React from "react";
import SEO from "../common/SEO";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
import HabitacaoContent from "../elements/solutionsContent/HabitacaoContent";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
import FooterTwo from '../common/footer/FooterTwo';
import SoftwarePersonalizadoContent from "../elements/solutionsContent/SoftwarePersonalizadoContent";

const SoftwarePersonalizado = () => {
    return (
        <>
            <SEO title="Software Personalizado - Precisa Tecnologia" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <div className="header-transparent-with-topbar">
                    <HeaderOne
                        btnStyle="btn-small btn-icon"
                        HeaderSTyle="header-not-transparent"
                    />
                </div>

                {/* Image Header  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/banner-personalizado.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Nossa Solução</span></h3>
                                    </div>
                                    <h1 className="title display-one"><span className="theme-gradient" >Softwares Personalizados</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" style={{ marginTop: '6rem' }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Realizamos o desenvolvimento e participamos da estratégia para as aplicações web utilizando práticas de desenvolvimento ágil que impactam e fazem total diferença no sucesso do seu negócio. 🚀</p>
                            <p>Possuímos a motivação em fazer parte do negócio do nosso cliente, não apenas para vender um produto, mas também para alavancar o negócio de nossos clientes e desenvolver juntos o produto digital ideal para o seu negócio. Trabalhamos com a execução e resoluções de longo prazo que trazem resultado para a sua empresa.</p>
                        </div>
                    </div>
                    <SoftwarePersonalizadoContent />
                </div>

                {/*Rodapé*/}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    );
};

export default SoftwarePersonalizado;