import { useEffect, useState } from 'react'
import Identifier from './Identifier'
import Chat from './Chat'
import Header from './Header'

const Box = ({ open }) => {
    const [logged, setLogged] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const login = e => {
        e.preventDefault()
        if (name.length > 0 && email.length > 0) {
            fetch(`${process.env.REACT_APP_CHAT_API}?type=getUser&name=${name}&email=${email}`)
                .then(res => res.json())
                .then(data => {
                    if (data && data.id > 0) {
                        //? Ok, tudo certo
                        const id = data.id
                        localStorage.setItem('chatUser', JSON.stringify({ id, name, email }))
                        setLogged(true)
                    }
                })
                .catch(err => console.log(err))
        }
    }

    useEffect(() => {
        const chatUser = JSON.parse(localStorage.getItem('chatUser'))
        chatUser ? setLogged(true) : setLogged(false)
    }, [])

    return (
        <div
            style={{
                position: 'fixed',
                bottom: logged ? '230px' : '175px',
                right: '20px',
                width: '400px',
                height: '500px',
                backgroundColor: '#FFF',
                borderRadius: '16px 16px 0 0',
                zIndex: '1000'
            }}
        >
            <Header title='Precisa' />
            {logged ? <Chat open={open} /> : <Identifier login={login} setName={setName} setEmail={setEmail} />}
        </div>
    )
}

export default Box
