import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Inicio from "./pages/Inicio";
import QuemSomos from "./pages/QuemSomos";
import Habitacao from './pages/Habitacao';
import AssistenciaSocial from './pages/AssistenciaSocial';
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";

// Import Css Here
import './assets/scss/style.scss';
import SoftwarePersonalizado from './pages/SoftwarePersonalizado';
import Layout from './common/Layout';

const App = () => {
    return (

        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Inicio} />
                    <Route path={`${process.env.PUBLIC_URL + "/habitacao"}`} exact component={Habitacao} />
                    <Route path={`${process.env.PUBLIC_URL + "/assistencia-social"}`} exact component={AssistenciaSocial} />
                    <Route path={`${process.env.PUBLIC_URL + "/software-personalizado"}`} exact component={SoftwarePersonalizado} />
                    <Route path={`${process.env.PUBLIC_URL + "/quem-somos"}`} exact component={QuemSomos} />
                    <Route path={`${process.env.PUBLIC_URL + "/contato"}`} exact component={Contact} />
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error} />
                </Switch>
            </PageScrollTop>
        </Router>

    )
}

export default App