import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'rodrigo',
        name: 'Rodrigo ',
        designation: 'Sócio Proprietário',
        location: 'Chapecó - SC', 
        description: 'Suporte técnico, assessoria e pós venda',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
    },
    {
        image: 'paulo',
        name: 'Paulo',
        designation: 'Sócio Proprietário',
        location: 'Chapecó - SC', 
        description: 'Responsável pelo setor comercial',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiLinkedin />,
                url: '#'
            },
        ]
    },
    {
        image: 'fabricio',
        name: 'Fabrício',
        designation: 'Analista de Suporte',
        location: 'Chapecó - SC',  
        description: 'Analista de suporte e implantação',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiLinkedin />,
                url: '#'
            },
        ]
    },
    {
        image: 'roberto',
        name: 'Roberto',
        designation: 'Desenvolvedor',
        location: 'Chapecó - SC',  
        description: 'Responsável pela criação de aplicações web',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'jonatan',
        name: 'Jonatan',
        designation: 'Desenvolvedor e Suporte Técnico',
        location: 'Chapecó - SC', 
        description: 'Suporte técnico e desenvolvedor de aplicações web',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'maicom',
        name: 'Maicom',
        designation: 'Suporte Técnico',
        location: 'Chapecó - SC',  
        description: 'Suporte técnico',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'lucas',
        name: 'Lucas',
        designation: 'Suporte Técnico',
        location: 'Chapecó - SC', 
        description: 'Suporte técnico',
        socialNetwork: [
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
]

const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    {/* <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul> */}
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
