import React from "react";
import SEO from "../common/SEO";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import BlogClassicData from "../data/blog/BlogList.json";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Mapa from "../elements/mapa/Mapa";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import './Corporate.css'
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import { useRef } from 'react';
import { Link } from "react-router-dom";

// Fotos Slider Inicio
const BannerData = [
    {
        image: "/images/banner/banner-assistencia-social.jpg",
        title: "Somos referência em<br/>Assistência Social",
        description: "Aprovado e indicado por mais de 50 clientes.",
        link: '/assistencia-social/'
    },
    {
        image: "/images/banner/banner-habitacao.jpg",
        title: "Software de Habitação",
        description: "Integrado com o sistema de Assistência Social",
        link: '/habitacao/'
    },
    {
        image: "/images/banner/banner-personalizado.jpg",
        title: "Softwares Personalizados",
        description: "Desenvolvemos o Software específico conforme a sua necessidade",
        link: '/software-personalizado/'
    },
];
const Inicio = () => {

    // Go to the specific div element
    const refSolucoes = useRef(null);
    const refClientes = useRef(null);
    const refDepoimentos = useRef(null);
    const scrollToElement = () => {
        const url = window.location.href
        const partsUrl = url.split('#')
        if (partsUrl[1]) { // #algumacoisa
            switch (partsUrl[1]) {
                case 'solucoes':
                    refSolucoes.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'clientes':
                    refClientes.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'depoimentos':
                    refDepoimentos.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
            }
        }
    }
    setTimeout(scrollToElement, 1000)

    return (
        <>
            <SEO title="Precisa Tecnologia" />

            <main className="page-wrapper">
                <HeaderTopBar />
                <div className="header-transparent-with-topbar">
                    <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Slider  */}
                <Slider
                    className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
                    {...BannerActivation}
                >
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide" >
                            <div
                                className="height-950 bg_image"
                                data-black-overlay="7"
                                style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                                }}
                            >
                                <div className="container" >
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
                                                <h1
                                                    className="title"
                                                    dangerouslySetInnerHTML={{ __html: data.title }}
                                                ></h1>
                                                <p
                                                    className="description"
                                                    dangerouslySetInnerHTML={{ __html: data.description }}
                                                ></p>
                                                <div className="button-group mt--30">
                                                    <Link className="btn-default btn-large round" to={data.link} >
                                                        Saiba mais
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

                {/* Cards das nossa soluções*/}
                <div ref={refSolucoes} className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Soluções"
                                    title="Nossas soluções"
                                    description="Segue abaixo nossas soluções <br /> Clique para saber mais"
                                />
                            </div>
                        </div>
                        <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
                    </div>
                </div>

                <Separator />

                {/*Mapa de atuação*/}
                <div ref={refClientes} className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <Mapa />
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <h1>ESTAMOS NOS 3 ESTADOS DO SUL DO BRASIL</h1>
                                    <p>A Precisa Gestão em Tecnologia e Serviços atua em toda a região sul do Brasil com diversos sistemas e prestando serviços de melhor qualidade.</p>
                                    <p>Trabalhamos com sistemas para a gestão de diversos setores, tendo desde a gestão de toda a área de Assistência Social e Habitação para os municípios.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                {/*Números / Resultados*/}
                <div className="rwt-counterup-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Contadores"
                                    title="Nossos Resultados"
                                    description=""
                                />
                            </div>
                        </div>
                        <CounterUpTwo
                            column="col-lg-6 col-md-6 col-sm-6 col-12"
                            counterStyle="counter-style-2"
                            textALign="text-center"
                        />
                    </div>
                </div>

                {/* Depoimentos  */}
                <div ref={refDepoimentos} className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="O que falam sobre nós"
                                    title="Depoimentos de Clientes"
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>

                {/*Botão Whats*/}
                <CalltoActionFive />

                {/*Rodapé*/}
                <Separator />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    );
};

export default Inicio;